// src/icons.ts
import {
  BabyIcon,
  BookCheckIcon,
  BookOpenCheckIcon,
  BookPlusIcon,
  BotIcon,
  CoinsIcon,
  CopyIcon,
  DicesIcon,
  DownloadIcon,
  EarIcon,
  FilePlusIcon,
  FlagIcon,
  FlaskConicalIcon,
  FolderTreeIcon,
  FrownIcon,
  GlassesIcon,
  Globe2Icon,
  LaptopIcon,
  LockKeyholeIcon,
  MoonIcon,
  PencilIcon,
  SchoolIcon,
  SnowflakeIcon,
  SquareStackIcon,
  StarsIcon,
  SubscriptIcon,
  SunIcon,
  TestTube2Icon,
  Wand2Icon,
  WholeWordIcon,
  ZapIcon
} from "lucide-react";
var icons = {
  BabyIcon,
  BookCheckIcon,
  BookOpenCheckIcon,
  BookPlusIcon,
  BotIcon,
  CoinsIcon,
  CopyIcon,
  DicesIcon,
  DownloadIcon,
  EarIcon,
  FilePlusIcon,
  FlagIcon,
  FlaskConicalIcon,
  FolderTreeIcon,
  FrownIcon,
  GlassesIcon,
  Globe2Icon,
  LaptopIcon,
  LockKeyholeIcon,
  MoonIcon,
  PencilIcon,
  SchoolIcon,
  SnowflakeIcon,
  SquareStackIcon,
  StarsIcon,
  SubscriptIcon,
  SunIcon,
  TestTube2Icon,
  Wand2Icon,
  WholeWordIcon,
  ZapIcon
};

// src/models.ts
var models = [
  "gpt-4o",
  "gpt-4o-mini",
  "gpt-4-turbo-preview",
  "gpt-4",
  "gpt-3.5-turbo",
  "gpt-3.5-turbo-16k",
  // specific models
  "gpt-4-1106-preview",
  "gpt-3.5-turbo-1106",
  "gpt-3.5-turbo-16k",
  "gpt-4-0613"
];

// src/index.ts
var roles = ["UNKNOWN", "STUDENT", "TEACHER", "ROOT"];
var defaultLocale = "sv";
var locales = ["sv", "no", "de", "en", "nl", "nn", "da"];
var localeTable = {
  sv: { nativeName: "Svenska", emoji: "\u{1F1F8}\u{1F1EA}" },
  no: { nativeName: "Norsk (Bokm\xE5l)", emoji: "\u{1F1F3}\u{1F1F4}" },
  de: { nativeName: "Deutsch", emoji: "\u{1F1E9}\u{1F1EA}" },
  en: { nativeName: "English", emoji: "\u{1F1EC}\u{1F1E7}" },
  nl: { nativeName: "Nederlands", emoji: "\u{1F1F3}\u{1F1F1}" },
  nn: { nativeName: "Nynorsk", emoji: "\u{1F1F3}\u{1F1F4}" },
  da: { nativeName: "Dansk", emoji: "\u{1F1E9}\u{1F1F0}" }
};
var domainTable = {
  "nextgenclassroom.se": "sv",
  "nxtgen.no": "no",
  "nextgenlearning.se": "de",
  "nextgenclassroom.eu": "en",
  // local
  "localhost:3000": "sv",
  "no.localhost:3000": "no",
  "de.localhost:3000": "de",
  "en.localhost:3000": "en"
};
var nextgenStaff = [
  "max@nextgenclassroom.se",
  "ella@nextgenclassroom.se",
  "ella.udd-brodin@vaxjo.se"
];
var iconKeys = Object.keys(icons);
export {
  defaultLocale,
  domainTable,
  iconKeys,
  icons,
  localeTable,
  locales,
  models,
  nextgenStaff,
  roles
};
