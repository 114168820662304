import(/* webpackMode: "eager", webpackExports: ["MainNav"] */ "/app/apps/nextjs/src/app/(marketing)/main-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocaleSwitcher"] */ "/app/apps/nextjs/src/components/locale-switcher/client-component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModeToggle"] */ "/app/apps/nextjs/src/components/mode-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Translation"] */ "/app/apps/nextjs/src/components/translations/component.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._33bjf7pzi7mx4rsfbv7ax2bb7q/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.9_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@18._33bjf7pzi7mx4rsfbv7ax2bb7q/node_modules/next/dist/client/link.js");
