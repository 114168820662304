"use client";

import { type ReactNode, createContext, useContext, useEffect } from "react";

import { createLocalization } from "./actions";

const TranslationContext = createContext<Record<string, string>>({});

interface Props {
  value: Record<string, string>;
  children: ReactNode;
}

export const TranslationProvider: React.FC<Props> = ({ value, children }) => {
  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = (
  id: string,
  defaultValue?: string,
  replace?: Record<string, string | number>,
): string => {
  const context = useContext(TranslationContext);

  if (context === undefined) {
    throw new Error("useTranslation must be used within a TranslationProvider");
  }

  const translation = context[id];

  useEffect(() => {
    if (!translation && defaultValue) {
      void createLocalization({ id, text: defaultValue.trim() });
    }
  }, [id, translation, defaultValue]);

  let string = context[id] ?? defaultValue ?? id;
  if (replace) {
    string = string.replace(
      /{\s*(\w+)\s*}/g,
      (_, key: string) => replace[key.trim()]?.toString() ?? "",
    );
  }
  return string;
};
